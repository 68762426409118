<template>
    <section class="register-page">
        <div class="registerCard-sec">
            <div class="headingBox text-center mb-5">
                <h2>Nevada Restaurant Association</h2>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="registerFormBox">
                        <router-link to="/nvra-register">
                            <i class="fas fa-user"></i>
                            Individual Sign Up
                        </router-link>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="registerFormBox registerFormBox2">
                        <router-link to="/nvra-corporate-register">
                            <i class="fas fa-users"></i>
                            Company Sign Up
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import "sweetalert2/src/sweetalert2.scss";
import '@/nra/partials/style.css';
import '@/nra/partials/responsive.css';
export default {
    components: {
    },
    data() {
        return {};
    },
    created: function () {
    },
    methods: {}
};
</script>
<style scoped>
body a:hover {
    color: #ffffff !important;
}
</style>